var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"left-panel",attrs:{"id":"left-panel"}},[_c('nav',{staticClass:"navbar navbar-expand-sm navbar-default"},[_c('div',{staticClass:"main-menu collapse navbar-collapse",attrs:{"id":"main-menu"}},[_c('ul',{staticClass:"nav navbar-nav"},[_c('router-link',{attrs:{"to":"/"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isExactActive = ref.isExactActive;
var active = ref.active;
return [_c('li',{class:[active && 'router-link-active',
             isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Inicio"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-home"}),_vm._v("Inicio ")])])]}}])}),_c('router-link',{attrs:{"to":"/tasks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active', isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Actividades"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-tag"}),_vm._v(" Actividades ")])])]}}])}),_c('router-link',{attrs:{"to":"/courses"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active', isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Cursos"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-list-alt"}),_vm._v(" Cursos ")])])]}}])}),_c('router-link',{attrs:{"to":"/notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active', isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Notas"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-list-ol"}),_vm._v(" Notas ")])])]}}])}),_c('router-link',{attrs:{"to":"/notifications"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active',
             isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Notificaciones"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-bell"}),_vm._v(" Notificación ")])])]}}])}),_c('router-link',{attrs:{"to":"/assistance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active', isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Asistencia"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-check-circle"}),_vm._v(" Asistencia ")])])]}}])}),_c('router-link',{attrs:{"to":"/sanciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active',
             isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Sanciones"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-exclamation-circle"}),_vm._v(" Sanciones ")])])]}}])}),_c('router-link',{attrs:{"to":"/top"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active',
             isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Top"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-sort-numeric-desc"}),_vm._v(" Top ")])])]}}])}),_c('router-link',{attrs:{"to":"/percent_assistant"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
             var href = ref.href;
             var route = ref.route;
             var navigate = ref.navigate;
             var isExactActive = ref.isExactActive;
             var active = ref.active;
return [_c('li',{class:[active && 'router-link-active',
             isExactActive && 'active']},[_c('a',{attrs:{"href":href,"title":"Asistencia por curso"},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fa fa-certificate"}),_vm._v(" Asistencia por curso ")])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }